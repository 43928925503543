export function publishEvent(
  eventName: string,
  publishingNode: Node[],
  eventData: any,
) {
  let customEvent: any;

  // @ts-ignore
  const isIE = !!document.documentMode;
  if (isIE) {
    customEvent = document.createEvent('CustomEvent');
    customEvent.initCustomEvent(eventName, true, false, eventData);
  } else {
    customEvent = new CustomEvent(eventName, {
      detail: eventData,
      bubbles: true,
    });
  }

  publishingNode.forEach(publishNode => {
    if (publishNode && publishNode.dispatchEvent) {
      publishNode.dispatchEvent(customEvent);
    }
  });
}

export const EVENT_NAMES = {
  CONSENT_POLICY_MANAGER_READY: 'consentPolicyManagerReady',
  CONSENT_POLICIY_INITIALIZED: 'consentPolicyInitialized',
  CONSENT_POLICY_CHANGED: 'consentPolicyChanged',
  CONSENT_POLICY_UPDATE_REQUESTED: 'consentPolicyUpdateRequested',
};
